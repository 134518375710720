export enum StageEnum {
  Alpha = "alpha",
  Beta = "beta",
  Release = "release",
}

export enum SuscirptionEnum {
  Basic = "Basic",
  Standard = "Standard",
  Premium = "Premium",
  PayAsYouGo = "PayAsYouGo",
}

export enum RoleEnum {
  User = "User",
  Admin = "Admin",
  Test = "Test",
}
